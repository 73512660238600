import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { userLogin } from 'store/user';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';
import PhoneInput from 'components/PhoneInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import PureCheckBox from 'components/PureCheckBox';
import LogoImage from 'assets/Basiligo-Logo.png';
import LogoImageIcon from 'assets/b-logo-icon.png';
import DietPlanIcon from 'assets/diat-plan.gif';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      username: '', 
      first_name:'',
      last_name:'',
      password: '',
      sameaswhatsapp:1,
      phone:'',
      whatsUpnumber:'',
      WPC_CODE:'',
      PC_CODE:'',
      referer: '',
      showPassword: false,
      errorModalMessage: '',
      close: () => this.setState({ errorModalMessage: '' }),
      xyz: false,
      activeStep:1
    };
  }

  socketRegistration = action => {
    if (this.SignUpRef) {
      if (action.type === 'setOk') {
        this.props.login(action.data);
        browserHistory.push('/');
      } else if (action.type === 'setErr') {
        // let activeState = ()
        // Object.keys(action.data).map()
        const filteredArray = (Object.keys(action.data)).filter(value => ['first_name','last_name','phone','whatsUpnumber'].includes(value));
        const activeStep = (filteredArray.length>0)?1:2;
        this.setState({ errors: action.data,activeStep });
      } else if (['facebookErr', 'googleErr'].indexOf(action.type) > -1) {
        this.setState({ errorModalMessage: action.message });
      } else if (action.type === 'checkRefOk') {
        this.setState({ referer: action.data.refId });
      } else if (action.type === 'checkRefErr') {
        this.setState({
          errorModalMessage: action.message,
          close: () => {
            this.setState({ errorModalMessage: '' });
            browserHistory.push('/sign-up');
          }
        });
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('registration', this.socketRegistration);
    const { params } = this.props;
    if ('token' in params && params.token) {
      this.props.socket.emit('registration', { type: 'checkRef', data: { token: this.props.params.token } });
    }
  }

  componentWillUnmount() {
    this.props.socket.removeListener('registration', this.socketRegistration);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  handleOptionChange = (name, value) => {
    this.setState({ [name]: value });
  };
  
  submit(e, browserId,activeStep) {
    e.preventDefault();
    const { email, password, referer, first_name, last_name} = this.state;
    let phone =this.state.phone;
    let whatsUpnumber =this.state.whatsUpnumber;
    let phoneCode ='';
    let whatCode ='';
    if(whatsUpnumber!='' && whatsUpnumber!=null){
      let phcode = parsePhoneNumber(whatsUpnumber);
      console.log(phcode);
      whatCode= (phcode)?'+'+phcode.countryCallingCode:null;
      whatsUpnumber=phcode.nationalNumber;
    }
    if(phone!='' && phone!=null ){
      let phcode =parsePhoneNumber(phone);
      //console.log(phcode);
      phoneCode= (phcode)?'+'+phcode.countryCallingCode:null;
      phone=phcode.nationalNumber;
    }
    this.props.socket.emit('registration', { type: 'set', data: { email, first_name:first_name,last_name:last_name, password, referer, PC_CODE:phoneCode, WPC_CODE:whatCode, phone, whatsUpnumber, browserId } });
  }

  submitFacebook(response, browserId) {
    if ('id' in response) {
      response.type = this.state.type;
      response.language = this.props.language;
      this.props.socket.emit('registration', { type: 'facebook', data: { ...response, browserId } });
    }
  }

  submitGoogle(response, browserId) {
    response.type = this.state.type;
    response.language = this.props.language;
    this.props.socket.emit('registration', { type: 'google', data: { ...response, browserId } });
  }

  render() {
    const { errors, errorModalMessage, email, password, xyz, close , first_name, last_name, whatsUpnumber, phone, PC_CODE, WPC_CODE, sameaswhatsapp, activeStep} = this.state;
    const onChange = (name, value) => this.setState({ [name]: value });
    const onWhatChange = (name, value) =>{
      this.setState({ [name]: value });
      if(sameaswhatsapp){
        this.setState({ 'phone': value });
      }
    };
    const { settings, browserId } = this.props;
    const facebookAppId = settings.getValue('FACEBOOK_APP_ID');
    const googleAppId = settings.getValue('GOOGLE_APP_ID');
    // console.log(errors)
    return (
      <div ref={el => (this.SignUpRef = el)} >
        <div className='sign-container'>
        <div className='sign-left'>
            <div className='sign-left-inner '><h5>Build your menu before payment,</h5>
            <p>Just Sign In Required</p></div>
        </div>
        <div className={`sign-right`}>
        <div className='logo'>  <Link to='/' onClick={() => stop()} onlyActiveOnIndex><img src={LogoImage} alt='Loseweight' /></Link></div>
        <div className='logo-icon'>  <Link to='/' onClick={() => stop()} onlyActiveOnIndex><img src={LogoImageIcon} alt='Loseweight' /></Link></div>
        <div class="marquee">
          <div class="notify-text">
            <img src={DietPlanIcon} alt='Loseweight' />
            <div class="diet-text">
              <p>Plan your menu and choose your meals completely free before starting.</p><p>Sign in to start, select your macros and see the dish options.</p> 
            </div>
          </div>
        </div>
        <div className='sign-up-form'>
        <div className='sign-in-top mt-1'>
           <Link to='/sign-in' className='colorTitle'>Sign In</Link>
          <span className='colorTitle ml-3'>Sign Up</span>
          </div>
          <form className='form'>
          
          {activeStep == 1 &&<Fragment>
            <div className='sign-form-container'>
              <div className='sign-up-row mt-4'>
                  <div className='sign-up-input mr-3'>
                  <label class="textLeft">First Name</label>
                      <InputValidation
                        value={first_name}
                        name='first_name'
                        onChange={onChange}
                        errors={errors}
                        placeholder='First Name'
                        inputSelfClassName='standart-input b-rounded-blue'
                      />
                    </div>
                    <div className='sign-up-input'>
                    <label class="textLeft">Last Name</label>
                      <InputValidation
                        value={last_name}
                        name='last_name'
                        onChange={onChange}
                        errors={errors}
                        placeholder='Last Name'
                        inputSelfClassName='standart-input b-rounded-blue'
                      />
                    </div>
            </div>
            <div className='sign-up-input mt-4'>
              <label className='textLeft'>WhatsApp Number</label>
              <PhoneInput
                classError='has-error letter-xs'
                errors={errors}
                labelText={'WhatsApp Number'}
                phoneProps={{ 
                  value:(WPC_CODE!=null && WPC_CODE!=undefined && whatsUpnumber!=undefined)?WPC_CODE+(whatsUpnumber.replace(WPC_CODE,'')):whatsUpnumber,
                    defaultCountry:"AE",
                    international:true,
                    name:'whatsUpnumber',
                    placeholder:'WhatsApp Number',
                    countryCallingCodeEditable:false,
                    className:'standart-input b-rounded-blue',
                    onChange:(value) => onWhatChange('whatsUpnumber', value)
                }}
              />
            </div>
            <div className='sign-up-input mt-3'>
              <div className='checkbox-con'>
              <PureCheckBox value={sameaswhatsapp} id="sameaswhatsapp" name="sameaswhatsapp" onChange={(name,checked,val)=>{
                this.setState({sameaswhatsapp:checked});
                if(checked){
                  this.setState({phone:whatsUpnumber});
                }
              }} />
              <div className='checkbox-label-con'>
                 <label className='checkbox-label' for="sameaswhatsapp">Same number for WhatsApp</label>
              <span className='checkbox-sublabel'>All communication will be through WhatsApp</span>
              </div>
              </div>
              </div>
              {!sameaswhatsapp && <div className='sign-up-input mt-4'>
              <label className='textLeft'>Calling Number</label>

              <PhoneInput
              errors={errors}
              labelText={'Calling Number'}
              classError='has-error letter-xs'
                 phoneProps={{ 
                      value:(PC_CODE!=null && PC_CODE!=undefined && phone!=undefined)?PC_CODE+(phone.replace(PC_CODE,'')):phone,
                      defaultCountry:"AE",
                      className:'standart-input b-rounded-blue',
                      // labels:{'phone':'Calling Number'},
                      name:'phone',
                      international:true,
                      placeholder:'Calling Number',
                      countryCallingCodeEditable:false,
                      onChange:(value) => onChange('phone', value)
                }}
              />
            </div>}
            </div>
            <div className='sign-up-input mt-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input onClick={e => this.submit(e, browserId,activeStep)} type='submit' value='Continue' className='form-button letter-sm w-100' />
            </div></Fragment>}
            {activeStep == 2 && <Fragment><div className='sign-form-container'><div className='sign-up-input mt-4'>
            <label class="textLeft">Email address</label>
              <InputValidation
                value={email}
                name='email'
                onChange={onChange}
                errors={errors}
                placeholder='Enter your email address'
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='sign-up-input mt-4'>
            <label class="textLeft">Password</label>
              <InputValidation
                type='password'
                value={password}
                name='password'
                onChange={onChange}
                errors={errors}
                placeholder='Enter your password'
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='search-title login-text mt-3'>
            <p className='line-height-xsnormal login-text mb-0'>
              {' By signing in or signing up, I agree to Lose Weight '}
              <Link to='terms' className='letter-xs'>{'Terms & Conditions'}</Link>, <Link to='/refunds' className='letter-xs'>{'Refund & Cancellation policy'}</Link>{' and '}
              <Link to='/privacy-policy' className='letter-xs'>{'Privacy Policy'}</Link>{',\n' +
            'I confirm that I am 18 years of age or older, and consent to receiving email communication.'}
            </p>
            </div></div>
            <div className='sign-up-input mt-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input onClick={e => this.submit(e, browserId,activeStep)} type='submit' value='Create Account' className='form-button letter-sm w-100' />
            </div>  <div className='mt-4 mr-1 text-center'>
            <span className='login-text' ><a href='javascript:void(0)' onClick={e=> this.setState({activeStep:1})}>Back</a></span>
          </div></Fragment>}
          </form>
          {/* <div className='search-title login-text mt-3'>
            <span className='colorTitle letter-sm'>Or</span>
          </div>
          {googleAppId && <GoogleLogin
            clientId={googleAppId}
            onSuccess={response => this.submitGoogle(response, browserId)}
            buttonText='Sign up with Google'
            className='sign-up-input mt-3 sign-up-button-google d-flex align-items-center justify-content-around'
            onFailure={data => console.log(data)}
          />}
          {facebookAppId && <FacebookLogin
            appId={facebookAppId}
            autoLoad={false}
            size='small'
            callback={response => this.submitFacebook(response, browserId)}
            fields='name,email,picture,first_name,last_name'
            textButton='Sign up with Facebook'
            cssClass='sign-up-input mt-3 mb-2 sign-up-button-facebook d-flex align-items-center justify-content-around'
          />} */}
          <div className='mt-4 mr-1 text-center sign-up-bottom'>
            <span className='mt-1 mr-1'>Already have an Account? </span>
          <span className='login-text'><Link to='/sign-in'>Sign In</Link></span>
          </div>
        
        </div>
        {errorModalMessage &&
          <Modal
            close={close}
            message={errorModalMessage}
          />
        }
      </div>
      </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  socket: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  browserId: PropTypes.string.isRequired,
  params: PropTypes.any.isRequired,
  login: PropTypes.func.isRequired,
  settings: PropTypes.object
};

const props = state => ({
  language: state.user.language,
  browserId: state.user.browserId,
  settings: state.settings
});

function actions(dispatch) {
  return {
    login: user => dispatch(userLogin(user))
  };
}

export default socketConnect(connect(props, actions)(SignUp));
