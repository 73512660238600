import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { userLogin } from 'store/user';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';
import { spin, stop } from 'store/spinner';
import LogoImage from 'assets/Basiligo-Logo.png';
import LogoImageIcon from 'assets/b-logo-icon.png';
import DietPlanIcon from 'assets/diat-plan.gif';
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      smscode: '',
      showsms: false,
      errorModalMessage: '',
      showPassword: false,
      errors: {},
      xyz: false
    };
  }

  socketLogin = ({ type, data, message, banned, smscode }) => {

    if (this.SignInRef) {
      if (type === 'smsOk') {
        this.setState({
          showsms: true,
          errorModalMessage: message,
        });
      } else if (type === 'setOk') {
        this.props.login(data);
        browserHistory.push('/');
      } else if (type === 'smsErr') {
        this.setState({
          errorModalMessage: message,
        });
      } else if (['setErr', 'facebookErr', 'googleErr'].indexOf(type) > -1) {
        const { errors } = data;
        const { email, password } = errors;
        if(email == undefined && password != undefined){
           message = password[0];
        }
        const bannedMessage = 'Dear Customer,\n' +
          'Thank you for your interest. We are really sorry but it seems that your profile cannot be activated. ' +
          'Please contact our team on team@loseweight.ae or 02-444-2595 for further clarifications.\n' +
          'Regards,\n' +
          'Lose Weight Team';
        this.setState({ errorModalMessage: banned ? bannedMessage : message });
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('login', this.socketLogin);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('login', this.socketLogin);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  submit(e, browserId) {
    e.preventDefault();
    const { email, password, smscode } = this.state;
	console.log('Login Trigger');
    this.props.socket.emit('login', { type: 'set', data: { email, password, browserId, app: 'customer', smscode: smscode } });
  }

  submitFacebook(response, browserId) {
    if ('id' in response) {
      this.props.socket.emit('login', { type: 'facebook', data: { ...response, browserId }, app: 'customer' });
    }
  } 

  submitGoogle(response, browserId) {
    this.props.socket.emit('login', { type: 'google', data: { ...response, browserId }, app: 'customer' });
  }

  render() {
    const onChange = (name, value) => this.setState({ [name]: value });
    const { errors, errorModalMessage, email, password, xyz, showsms, smscode } = this.state;
    const { settings, browserId,stop } = this.props;
    // const facebookAppId = settings.getValue('FACEBOOK_APP_ID');
    // const googleAppId = settings.getValue('GOOGLE_APP_ID');
    const facebookAppId = '';
    const googleAppId = '';
	//console.log(browserId);
    return (
      <div ref={el => (this.SignInRef = el)} className='full-width'>
        <div className='sign-container'>
        <div className='sign-left'>
            <div className='sign-left-inner '><h5>Build your menu before payment,</h5>
            <p>Just Sign In Required</p></div>
        </div>
        <div className={`sign-right`}>
        <div className='logo'>  <Link to='/' onClick={() => stop()} onlyActiveOnIndex><img src={LogoImage} alt='Loseweight' /></Link></div>
        <div className='logo-icon'>  <Link to='/' onClick={() => stop()} onlyActiveOnIndex><img src={LogoImageIcon} alt='Loseweight' /></Link></div>
        <div class="marquee">
          <div class="notify-text">
            <img src={DietPlanIcon} alt='Loseweight' />
            <div class="diet-text">
              <p>Plan your menu and choose your meals completely free before starting.</p><p>Sign in to start, select your macros and see the dish options.</p> 
            </div>
          </div>
        </div>
        <div className='sign-up-form'>
          <div className='sign-in-top mt-1'>
          <span className='colorTitle'>Sign In</span>
          <Link to='/sign-up' className='colorTitle ml-3'>Sign Up</Link>
          </div>
          <form className='form' onSubmit={e => this.submit(e, browserId)}>
            <div className='sign-form-container'>
            {!showsms && <div className='sign-up-input mt-4'><label class="textLeft">Email address</label>
              <InputValidation
                value={email}
                name='email'
                onChange={onChange}
                placeholder='Enter your email address'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
             {!showsms && <div className='sign-up-input mt-4'><label class="textLeft">Password</label>
              <InputValidation
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                placeholder='Enter your password'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
            {showsms && <div className='sign-up-input mt-4'>
              <InputValidation
                type='smscode'
                name='smscode'
                value={smscode}
                onChange={onChange}
                placeholder='Sms Code'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
            <div className='search-title login-text mt-3'>
            <p className='line-height-xsnormal login-text mb-0'>
              By signing in or signing up, I agree to Lose Weight &nbsp;
              <Link to='/terms' >Terms & Conditions</Link>, <Link to='/refunds' >Refund & Cancellation policy</Link>, <Link to='/privacy-policy' >Privacy Policy</Link>,
              I confirm that I am 18 years of age or older, and consent to receiving email communication.
            </p>
            </div>
            <div className='login-text mt-3  text-right'>
              <span><Link to='/restore'>Forgot password?</Link></span>
            </div></div>
            <div className='sign-up-input mt-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input type='submit' value='Login' className='form-button letter-sm w-100' />
            </div>
          </form>
          {/*<div className='search-title login-text mt-3'>
            <span className='colorTitle letter-sm'>Or</span>
    </div>*/}
          {googleAppId && <GoogleLogin
            clientId={googleAppId}
            onSuccess={response => this.submitGoogle(response, browserId)}
            buttonText='Sign in with Google'
            className='sign-up-input mt-3 sign-up-button-google d-flex align-items-center justify-content-around'
            onFailure={data => console.log(data)}
          />}
          {facebookAppId && <FacebookLogin
            appId={facebookAppId}
            size='small'
            callback={response => this.submitFacebook(response, browserId)}
            fields='name,email,picture,first_name,last_name'
            textButton='Sign in with Facebook'
            cssClass='sign-up-input mt-3 mb-2 sign-up-button-facebook d-flex align-items-center justify-content-around'
          />}
          <div className='mt-4 mr-1 text-center sign-up-bottom'>
         <span className='mt-1 mr-1'>Don’t have an Account? </span>
          <span className='login-text'><Link to='/sign-up'>Create your Account</Link></span>
          </div>
        </div>
        {errorModalMessage &&
          <Modal
            close={() => this.setState({ errorModalMessage: '' })}
            message={errorModalMessage}
          />
        }
        </div>
      </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  socket: PropTypes.object.isRequired,
  settings: PropTypes.object,
  browserId: PropTypes.string,
  login: PropTypes.func.isRequired
};

const props = state => ({
  browserId: state.user.browserId,
  language: state.user.language,
  settings: state.settings
});

const actions = dispatch => ({
  login: user => dispatch(userLogin(user)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions)(SignIn));
